import 'core-js/stable'
import Vue from 'vue'
//import CoreuiVuePro from '@coreui/vue-pro'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'
import axios from 'axios'

Vue.use(CoreuiVuePro)
const api = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  withCredentials: true
})
Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  i18n,
  provide: {
    api: api
  },
  template: '<App/>',
  components: {
    App
  }
})
import Vue from 'vue'
import Router from 'vue-router'

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const RegisterForAdmission = () => import('@/views/pages/RegisterForAdmission')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: 'registerforadmission',
    },
    {
      path: '/registerforadmission',
      name: 'Register',
      component: RegisterForAdmission
    },
    {
      path: '/404',
      name: '404',
      component: Page404
    },
    {
      path: '/500',
      name: '500',
      component: Page500
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404'
    }
  ]
})
